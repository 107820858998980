import { css, keyframes, SerializedStyles } from '@emotion/react'
import { useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { Nullable } from 'tsdef'
import { useTickChanges } from '~/modules/SDK/ticktock/useTickChanges'

/**
 * 持續傳入「數字」返回該數字相較於前次傳入之「上色動畫 CSS 物件」
 *
 * 當相較前次為上漲時，返回一個紅色動畫 CSS 物件，反之為綠色動畫 CSS 物件
 *
 * @example
 *   const updatedAnimationCSS = useQuoteUpdateAnimationCSS(symbolData.close)
 *
 *   return (
 *     <Paper
 *       elevation={1}
 *       css={css`
 *         box-sizing: border-box;
 *         border: 1px solid #cbcbcb;
 *         padding: 4px;
 *         color: ${getTextColor(quoteChanges.closeChangePercent)};
 *
 *         ${updatedAnimationCSS}
 *       `}
 *     >
 *       台積電 {symbolData.close}
 *     </Paper>
 *   )
 */
export const useQuoteUpdateAnimationCSS = (price = 0) => {
  const [updatedAnimation, setUpdatedAnimation] = useState<Nullable<SerializedStyles>>()
  const prevPrice = useTickChanges(price)

  useUpdateEffect(() => {
    const quoteUpdateAnimationLight = keyframes`
      25%, 75% {
        background-color: ${prevPrice.upThanPrevious ? '#ffabab55' : '#2a9c2a38'};
      }

      50% {
        background-color: ${prevPrice.upThanPrevious ? '#d3828255' : '#2a9c2a52'};
      }
    `

    setUpdatedAnimation(css`
      animation: ${quoteUpdateAnimationLight} 750ms ease-out 1;
      label: ${useQuoteUpdateAnimationCSS.name +
      '--' +
      (prevPrice.upThanPrevious ? 'priceUp' : 'priceDown')};
    `)

    const timerId = globalThis.setTimeout(() => {
      setUpdatedAnimation(null)
    }, 750)

    return () => {
      globalThis.clearTimeout(timerId)
    }
  }, [price])

  return updatedAnimation
}
