import { css } from '@emotion/react'
import { Paper, PaperProps } from '@mui/material'
import { memo, Fragment } from 'react'
import { useMount, useUnmount } from 'react-use'
import useMedia from '~/hooks/useMedia'
import { getQuoteTextColor } from '~/modules/AppLayout/colorUtils'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { useQuoteUpdateAnimationCSS } from '~/modules/SDK/socket/useQuoteUpdateAnimationCSS'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { __TEST__ } from '~/utils/__TEST__'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { fontWeight600 } from '~/css/font'
import { useThemeStore } from '~/components/theme/useThemeStore'

export const SignalrQuote = memo<
  ReactProps<
    {
      onClick?: PaperProps['onClick']
    } & (
      | {
          symbol: Signalr.SymbolString
        }
      | {
          symbol: Signalr.SymbolTopicString
          typing: 'TopicSymbolString'
        }
    )
  >
>(function SignalrQuote(props) {
  const { isPc } = useMedia()
  const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol])
  const quoteChanges = processQuoteToReadable(symbolInfo as Socket.ValueOfOHLC)
  const quoteAnimation = useQuoteUpdateAnimationCSS(symbolInfo?.close)

  useMount(() => {
    useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc')
  })

  useUnmount(() => {
    useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc')
  })
  const theme = useThemeStore(s => s.theme)

  return (
    <Paper
      className={props.className}
      elevation={1}
      css={css`
        width: 100%;
        ${flex.h.default};
        padding: ${isPc ? '4px 12px' : '8px'};
        margin: ${isPc ? 4 : 3}px;
        cursor: pointer;
        user-select: none;
        ${quoteAnimation}

        #__next & {
          color: ${getQuoteTextColor(quoteChanges.closeChangePercent, theme === 'dark')};
        }
      `}
      onClick={event => {
        props.onClick?.(event)
      }}
    >
      <div
        css={css`
          flex: 1.2;
          ${verticalCss}
        `}
      >
        <p>
          <SymbolName symbol={props.symbol as AnyFIXME}></SymbolName>
        </p>
        <p>{symbolInfo ? quoteChanges.closeChangePercentString : '-'}</p>
      </div>

      <div
        css={css`
          flex: 1;
          ${verticalCss}
        `}
      >
        <p>{symbolInfo?.close ?? '-'}</p>
        <p>{symbolInfo ? quoteChanges.deltaChangeString : '-'}</p>
      </div>
    </Paper>
  )
})

const verticalCss = css`
  ${flex.v.default};
  * {
    margin: 0;
    &:nth-of-type(1) {
      font-size: 1rem;
      ${fontWeight600};
    }
    &:nth-of-type(2) {
      font-size: 0.7rem;
      ${fontWeight600}
    }
  }
`

/* istanbul ignore next */
/* eslint-disable @typescript-eslint/ban-ts-comment */
if (__TEST__) {
  const TestComponent1: React.FC = props => {
    return (
      <Fragment>
        {/* 不報錯：當只有 props.symbol 時，不會作 typing check */}
        <SignalrQuote symbol='2409'></SignalrQuote>
        <SignalrQuote symbol='1314'></SignalrQuote>
        <SignalrQuote symbol='2881'></SignalrQuote>
        <SignalrQuote symbol='0050'></SignalrQuote>
        <SignalrQuote symbol='TX417000V2'></SignalrQuote>
        <SignalrQuote symbol='TXO17000V1'></SignalrQuote>

        {/* 不報錯：雖然 props.symbol 寫錯，但不會作 typing check */}
        <SignalrQuote symbol='1xxn'></SignalrQuote>

        {/**
         * 預期報錯：以下因為 Signalr.Topics 套餐之中沒有這個 symbolString
         *
         * 而 props.typing 參數使 TS 能對組件 symbolString typing check
         */}
        <SignalrQuote
          symbol='2407'
          typing='TopicSymbolString'
        ></SignalrQuote>
        <SignalrQuote
          symbol='1111'
          typing='TopicSymbolString'
        ></SignalrQuote>
        <SignalrQuote
          symbol='2406'
          typing='TopicSymbolString'
        ></SignalrQuote>
        <SignalrQuote
          symbol='240x'
          typing='TopicSymbolString'
        ></SignalrQuote>
        <SignalrQuote
          symbol='TX417000V2'
          typing='TopicSymbolString'
        ></SignalrQuote>
        <SignalrQuote
          symbol='XAUUSD'
          typing='TopicSymbolString'
        ></SignalrQuote>
        <SignalrQuote
          symbol='NAS100'
          typing='TopicSymbolString'
        ></SignalrQuote>
      </Fragment>
    )
  }
}
/* eslint-disable @typescript-eslint/ban-ts-comment */
