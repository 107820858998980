import React, { memo, useMemo } from 'react'
import { css } from '@emotion/react'
import useMedia from '~/hooks/useMedia'
import { ai, flex, oneGridArea } from '~/modules/AppLayout/FlexGridCss'
import { getGaugeStrengthColor } from '~/modules/AppLayout/colorUtils'
import { fontWeight600 } from '~/css/font'
import dynamic from 'next/dynamic'

const GaugeChart = dynamic(() => import('react-gauge-chart'), { ssr: false })

export const CustomizedGauge = memo<
  React.PropsWithChildren<{
    title?: string
    percent: number
    strength: number
  }>
>(function Gau(props) {
  const { percent, title, strength } = props
  const { isPc, isPad } = useMedia()

  const data = percent ? percent : 0
  const adjustData = data > 0 ? 0.5 + 0.5 * data : 0.5 - Math.abs(data) * 0.5

  const gauge = useMemo(
    () => (
      <GaugeChart
        id={'chart'}
        percent={adjustData}
        formatTextValue={() => ''}
        nrOfLevels={20}
      />
    ),
    [adjustData],
  )

  const text = useMemo(
    () => (
      <p
        css={css`
          color: ${getGaugeStrengthColor(data)};
        `}
      >
        {(strength > 0 ? '+' : '') + strength}
      </p>
    ),
    [strength, data],
  )

  return (
    <div css={oneGridArea.parentGrid('one')}>
      <div
        css={css`
          ${oneGridArea.childGridItem('one')}

          ${isPc
            ? null
            : isPad
            ? `svg {
            width: 300px;
            height: 135px;
          }`
            : null}
        `}
      >
        {gauge}
      </div>
      <div
        css={css`
          ${flex.h.mainCenter};
          ${ai.flexEnd}
          ${oneGridArea.childGridItem('one')};
          ${fontWeight600};
          p {
            font-size: 1.2rem;
            margin: 0 3px;
          }
        `}
      >
        <p>{title}</p>
        <div>{text}</div>
      </div>
    </div>
  )
})
