import range from 'lodash/range'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import {
  globalRed,
  globalGrey,
  globalGreen,
  globalYellow,
  globalOrange,
  globalBlueGreen,
} from '~/modules/AppLayout/Colors'

/** 台灣權值股 指標監控定義色 */
export const getPercentagDeltaColor = (closePercentage: number, isDarkMode?: boolean) => {
  const color = isDarkMode ? '#414554' : '#c6c8cf'

  if (closePercentage > 0) {
    if (closePercentage > 3) return '#f63538'
    if (closePercentage > 2) return '#bf4045'
    if (closePercentage > 1) return '#8b444e'
  } else {
    if (closePercentage < -3) return '#30cc5a'
    if (closePercentage < -2) return '#2f9e4f'
    if (closePercentage < -1) return '#35764e'
  }

  return color
}

/**
 * 台灣權值股 紅綠燈號
 *
 * 以高低點分六個區間（七個值）
 *
 * 越接近高點，代表可能創新高，鮮紅
 *
 * 越接近低點，代表可能創新低，鮮綠
 */
export const getRelativeStrongorWeak = (quote: Signalr.ValueOfOHLC, isDarkMode?: boolean) => {
  const { close, high, low } = quote
  const range7 = range(high, low, -(high - low) / 6).concat([low])
  const color = isDarkMode ? '#414554' : '#c6c8cf'

  // [0,1]
  if (close > range7[1]) return '#f63538'
  // [1,2]
  if (close > range7[2]) return '#bf4045'
  // [2,3]
  if (close > range7[3]) return '#414554'
  // [5,6]
  if (close < range7[5]) return '#2f9e4f'
  // [4,5]
  if (close < range7[4]) return '#35764e'
  // [3,4]
  if (close < range7[3]) return '#414554'

  return color
}

/*QuoteBox變化顏色 */
export const getQuoteTextColor = (closePercentage: number, isDarkMode?: boolean) => {
  let color

  if (closePercentage > 1) color = globalRed.r700
  else if (closePercentage <= 1 && closePercentage > 0.3) color = globalRed.r300
  else if (closePercentage <= 0.3 && closePercentage >= -0.3)
    color = isDarkMode ? globalGrey.g300 : globalGrey.g700
  else if (closePercentage >= -1 && closePercentage < -0.3) color = globalGreen.g300
  else if (closePercentage < -1) color = globalGreen.g800

  return color
}

/** 力道圖 */
const GAUGE_COLORS = [
  globalGreen.gA700,
  globalGreen.gA200,
  globalYellow.y700,
  globalOrange.o500,
  globalRed.r700,
]
export const getGaugeStrengthColor = (value: number) => {
  if (value > -0.1 && value < 0.1) return GAUGE_COLORS[2]
  else if (value > 0.6 && value < 1) return GAUGE_COLORS[4]
  else if (value < -0.6 && value > -1) return GAUGE_COLORS[0]
  else if (value > -0.6 && value < -0.1) return GAUGE_COLORS[1]
  else if (value < 0.6 && value > 0.1) return GAUGE_COLORS[3]
}

/** 趨勢圖 */
export const getAreaBackground = (action: string) => {
  switch (action) {
    case '':
      return globalGrey.g300
    case '-1':
      return globalBlueGreen.bg200
    case '1':
      return globalRed.r100
    default:
      return globalYellow.y100
  }
}
