import { Socket } from '~/modules/SDK/socket2/Socket'
import React, { memo } from 'react'
import { PriceChange } from '../../../screener/components/invesement-consultant/SymbolChangeRanking/PriceChange'
import {
  fill,
  fill_horizontal_all_center,
  fill_vertical_all_center,
  pureGrid,
} from '~/modules/AppLayout/FlexGridCss'
import { css } from '@emotion/react'
import { useDatePick } from '~/modules/screener/useDatePick'
import dayAPI from '~/utils/dayAPI'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import {
  useCheckPriceState,
  CheckTrendMode,
} from '../../../screener/components/invesement-consultant/SymbolChangeRanking/useCheckPriceState'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import styled from '@emotion/styled'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { fontWeight600 } from '~/css/font'

const Standard = memo<
  ReactProps<{
    symbol: Signalr.SymbolString
    currentSymbol: Signalr.SymbolString
    /** 擷取4個時間點的價格 */
    countArray: [number, number, number, number]
    /** 是否啟用趨勢過與濾過濾種類 */
    checkTrendMode?: CheckTrendMode
  }>
>(function Standard(props) {
  const lastTradeString = useDatePick()
  const lastTradeDay = dayAPI(lastTradeString)
  const currentSymbol = props.currentSymbol
  const theme = useThemeStore(s => s.theme)
  const isAvtive = props.symbol === props.currentSymbol ? '-active' : ''
  
  const checkResult = useCheckPriceState(
    lastTradeDay,
    props.symbol,
    props.countArray,
    props.checkTrendMode ?? 'NoCheck',
  )

  return (
    <classes.Root css={baseStyled}>
      {!checkResult ? null : (
        <classes.containerDefault className={theme + isAvtive}>
          <classes.name className={theme}>
            <div className={theme}>
              <SymbolName symbol={props.symbol} />
            </div>
            <div className={theme}>{props.symbol}</div>
          </classes.name>
          {props.countArray.map(datum => (
            <classes.value key={datum}>
              <PriceChange
                count={datum}
                symbolNumber={props.symbol}
                endOnSpecDate={lastTradeDay}
              />
            </classes.value>
          ))}
        </classes.containerDefault>
      )}
    </classes.Root>
  )
})

const Simple = memo<
  ReactProps<{
    symbolNumber: Socket.SymbolString
    useChart: ChartTypes.ChartingV2OrV4
    countArray: [number, number, number, number]
  }>
>(function Simple(props) {
  const lastTradeString = useDatePick()
  const lastTradeDay = dayAPI(lastTradeString)

  const theme = useThemeStore(s => s.theme)

  return (
    <classes.Root css={baseStyled}>
      <classes.containerSimple className={theme}>
        {props.countArray.map(datum => (
          <classes.value key={datum}>
            <PriceChange
              count={datum}
              symbolNumber={props.symbolNumber}
              endOnSpecDate={lastTradeDay}
            />
          </classes.value>
        ))}
      </classes.containerSimple>
    </classes.Root>
  )
})

const classes = {
  Root: styled.div`
    ${fill}
  `,
  containerDefault: styled.div`
    ${fill_horizontal_all_center}
    ${pureGrid};
    cursor: pointer;
    height: 40px;
    grid-template-columns: 25% 18.75% 18.75% 18.75% 18.75%;
    border-radius: 5px;
    transition: 0.3s;
  `,
  containerSimple: styled.div`
    ${fill_horizontal_all_center}
    ${pureGrid};
    height: 40px;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 0px 16px;
  `,
  name: styled.div`
    ${fill_vertical_all_center}
    font-size: 12px;
    ${fontWeight600};
  `,
  value: styled.div`
    width: 100%;
    height: 30px;
    padding: 2.5px;
    font-size: 14px;
  `,
}

const baseStyled = css`
  ${classes.Root} {
  }
  ${classes.containerDefault} {
    &.dark {
      background-color: #131416;
      border: 1px solid #3c3c3c;
      color: #ffffff;
      &:hover {
        background-color: #202020;
      }
    }
    &.light {
      background-color: #fefefe;
      border: 1px solid #ebebeb;
      color: #252525;
      &:hover {
        background-color: #fafafa;
      }
    }
    &.dark-active {
      background-color: #232020;
      border: 1px solid #eeeeee;
      color: #ffffff;
      &:hover {
        background-color: #1f1f1f;
      }
    }
    &.light-active {
      background-color: #fafafa;
      border: 1px solid #888888;
      color: #252525;
      &:hover {
        background-color: #fafafa;
      }
    }
  }
  ${classes.containerSimple} {
  }
  ${classes.name} {
    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #252525;
    }
  }
`

export default {
  standard: { Display: Standard },
  simple: { Display: Simple },
  classes,
}
