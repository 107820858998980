import { useMemo } from 'react'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { useSymbolHistoryOnceResource } from '~/modules/screener/containers/useSymbolHistoryResource'

import { Dayjs } from 'dayjs'
import { ADJUST_RECENT_DAY_FACTOR } from '~/modules/screener/constants'

export const useGetPrice = (
  endOnSpecDate: Dayjs,
  recentDays: number,
  count: number,
  symbolNumber: Socket.SymbolString,
) => {
  const { data: rawData } = useSymbolHistoryOnceResource({
    symbol: symbolNumber,
    fromTo: [
      endOnSpecDate
        .startOf('day')
        .add(-recentDays * ADJUST_RECENT_DAY_FACTOR, 'day')
        .unix(),
      endOnSpecDate.endOf('day').unix(),
    ],
  })

  const sliceCount = Math.max((rawData?.length ?? 0) - count - 1, 0)

  const data = useMemo(
    () =>
      rawData
        ?.map(datum => ({
          close: datum.close,
        }))
        .slice(sliceCount),
    [rawData, sliceCount],
  )

  return data
}
