import { memo } from 'react'
import { useAsyncFn } from 'react-use'
import { ValueOf } from 'type-fest'
import { apirc } from '~/configs/apirc'
import { useIntervalNow } from '~/hooks/useIntervalNow'
import { CustomizedGauge } from '~/modules/strength-state/Gauge'
import { getIndicatorAngular } from '~/modules/strength-state/getIndicatorAngular'

export const Display = memo<
  NoClassName<
    ReactProps<{
      /**
       * # 定期刷新
       *
       * ## 預設 10000ms
       */
      pollingInterval?: number

      /**
       * ## 預設
       *
       * - 當 `'dashboard_bs_indicator'` 預設 `'大戶力道'`
       * - 當 `'dashboard_trade_info'` 預設 `'大戶掛單'`
       */
      title?: string

      /**
       * # 後端API資料源
       *
       * - `'dashboard_bs_indicator'`＝ 大戶力道
       * - `'dashboard_trade_info'`＝ 大戶掛單
       */
      dataSource: 'dashboard_trade_info' | 'dashboard_bs_indicator'
    }>
  >
>(function Display(props) {
  const title: ValueOf<Record<(typeof props)['dataSource'], string>> = {
    dashboard_bs_indicator: '大戶力道',
    dashboard_trade_info: '大戶掛單',
  }[props.dataSource]

  const [{ value }, fetchValues] = useAsyncFn(async () => {
    const values = await apirc.marketDataAPI.dashboardData.fetch({ target: props.dataSource })

    return {
      strengthValue: values.value,
      percentValue: getIndicatorAngular(values.value),
    }
  }, [props.dataSource])

  useIntervalNow(() => {
    fetchValues()
  }, props.pollingInterval ?? 10000)

  return (
    <CustomizedGauge
      percent={value?.percentValue ?? 0}
      strength={value?.strengthValue ?? 0}
      title={title ?? '未知資料源'}
    />
  )
})

/** # 大戶掛單 */
export default {
  Display,
}
